<template>
<!-- imageText图文 text文字 -->
  <div id="imageText" class="app">
    <!-- header-fixed为页面下滑后导航样式 -->
    <div class="header header-fixed">
      <div class="logo"><a></a></div>
      <!-- in为小屏（<990px）滑出样式 -->
      <div id="menu" class="">
        <ul>
          <!-- menu-item-active为显示样式 -->
          <li class="menu-item ">
            <a class="menu-item-name">首页</a>
            <!-- menu-horizontal-active为大屏（>990px）鼠标经过menu-item-name的样式 -->
            <div class="menu-horizontal ">
              <a class="menu-horizontal-item" href="#">公司简介</a>
              <a class="menu-horizontal-item" href="#">组织结构</a>
              <a class="menu-horizontal-item" href="#">领导致辞</a>
              <a class="menu-horizontal-item" href="#">领导班子</a>
            </div>
          </li>
          <li class="menu-item">
            <router-link to="/about" class="menu-item-name">关于广路</router-link>
          </li>
          <li class="menu-item">
            <a class="menu-item-name">主营业务</a>
          </li>
          <li class="menu-item">
            <a class="menu-item-name">企业成员</a>
          </li>
          <li class="menu-item">
            <a class="menu-item-name">新闻中心</a>
          </li>
          <li class="menu-item">
            <a class="menu-item-name">党群建设</a>
          </li>
          <li class="menu-item ">
            <a class="menu-item-name">企业文化</a>
            <div class="menu-horizontal">
              <a class="menu-horizontal-item" href="#">企业精神</a>
              <a class="menu-horizontal-item" href="#">广路风采</a>
              <a class="menu-horizontal-item" href="#">广路故事</a>
              <a class="menu-horizontal-item" href="#">企业宣传片</a>
              <a class="menu-horizontal-item" href="#">国企书院</a>
            </div>
          </li>
          <li class="menu-item">
            <a class="menu-item-name">人力资源</a>
          </li>
          <li class="menu-item">
            <a class="menu-item-name">联系我们</a>
          </li>
        </ul>
      </div>
      <el-collapse accordion class="search">
        <el-collapse-item>
        <template slot="title">
          <i class="header-icon el-icon-info"></i>
        </template>
        <!-- 大屏显示 -->
        <div class="search-box">
          <div class="search-title">搜索资讯/文章</div>
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="">
              <el-input v-model="formInline.user" placeholder=""></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">搜索</el-button>
            </el-form-item>
          </el-form>
          <div class="search-close"></div>
        </div>
        <!-- 小屏显示 -->
        <div class="m-search-box">
          <el-form :inline="true" :model="formInline" class="demo-form-inline m-search-box-line">
            <el-form-item label="">
              <el-input v-model="formInline.user" placeholder=""></el-input>
            </el-form-item>
            <div class="m-search-box-close"></div>
          </el-form>
          <!-- 搜索结果 -->
          <div class="m-search-result">
            <ul>
              <li v-for="item in array2" :key="item.id" class="m-result-item">
                <router-link to="#" class="m-result-title">
                  <h4>{{item.title}}</h4>
                  <p>{{item.time}}</p>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        </el-collapse-item>
      </el-collapse>
      <div class="telephone">0771-2509900</div>
      <div class="m-header">
        <div class="m-menu"></div>
      </div>
    </div>
    <!-- party -->
    <!-- white标题白色  black标题黑色-->
    <div class="main black">
      <!-- 标题 -->
        <div class="content-title">
            <img class="hidden-xs" src="@/assets/img/bg-party.png">
            <img class="hidden-mx" src="@/assets/img/bg-party.png">
            <div class="content-box">
              <h1>党建观澜</h1>
              <div>学习贯彻习近平新时代中国特色社会主义思想</div>
            </div>
        </div>
        <!-- 内容 -->
        <div class="panel">
          <!-- 列表 -->
          <div class="listBox">
            <div v-for="item in imgList" :key="item.id" class="listBox-item">
                <router-link to="#" class="listBox-link">
                  <span class="listBox-item-img"><img :src="item.url"></span>
                  <div class="listBox-item-text">
                    <h3 class="listBox-item-title">{{item.title}}</h3>
                    <p class="listBox-item-time">{{item.time}}</p>
                  </div>
                </router-link>
            </div>
          </div>
          <!-- 分页 -->
          <div class="paging">
            <div class="paging-first">首页</div>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="100"
              layout="prev, pager, next, jumper"
              :total="1000">
            </el-pagination>
            <div class="paging-last">末页</div>
          </div>
        </div>
    </div>
    <!-- end party -->
    <!-- footer -->
    <div class="layout footer">
        <div class="footer-box">
          <div class="footer-up"></div>
          <div class="footer-left">
            <div class="footer-logo"></div>
            <p>Copyright 2020 广西广路实业投资集团 版权所有。<a href="http://www.baidu.com">桂ICP备17001807号-1</a></p>
          </div>
          <div class="footer-contact no1">
            <p><span>公司地址</span><span>广西南宁市望州路298号万里商务大酒店7F</span></p>
            <p><span>公司电话</span><span>0771-2509900</span></p>
            <p><span>公司传真</span><span>0771-2509902</span></p>
          </div>
          <div class="footer-code">
            <div class="footer-code-item">
              <img src="@/assets/img/erweima.png">
              <p>广路订阅号</p>
            </div>
            <div class="footer-code-item">
              <img src="@/assets/img/erweima.png">
              <p>服务号</p>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
	export default {
	  name: 'news',
    data: function(){
      return {
        input1: '',
        formInline: {
          user: '',
          region: ''
        },
        currentPage1: 5,
        array2:[
          {title:'箭发我国在文昌航天发射场成功完成长征七号改遥二运载火射',time:'2021/02/20'},
          {title:'阿联酋地处沙漠 为何还要进口沙子',time:'2021/02/19'},
          {title:'笑死！又一名拉练选手！张哲瀚吐槽粉丝体力不行：“就这种体力还追星？”',time:'2021/02/18'},
          {title:'阿联酋地处沙漠 为何还要进口沙子',time:'2021/02/17'},
          {title:'阿联酋地处沙漠 为何还要进口沙子2阿联酋地处沙漠 为何还要进口沙子2阿联酋地处沙漠 为何还要进口沙子2阿联酋地处沙漠 为何还要进口沙子2阿联酋地处沙漠 为何还要进口沙子2',time:'2021/02/16'}
        ],
        imgList:[
          {
            url:require("../assets/img/21.png"),
            title:'华为携手广东移动完成南方工厂5G室内分布式Massive MIMO创新试点，上行峰值吞吐率1.2Gbps',
            time:' 2021年03月10日'
            },
          {
            url:require("../assets/img/16.png"),
            title:'华为正式发布好望云服务，使能行业数字化转型',
            time:' 2021年03月10日'
            },
          {
            url:require("../assets/img/15.png"),
            title:'极简语音Single Voice Core，语音网演进最佳选择',
            time:' 2021年03月10日'
            },
          {
            url:require("../assets/img/21.png"),
            title:'华为与产业伙伴联合发布《5G确定性网络@电力系列白皮书II：5G电力虚拟专网建网模式》',
            time:' 2021年03月10日'
            },
          {
            url:require("../assets/img/13.png"),
            title:'北投物联科技与北投信创集团召开供应链管理平台（一期）开发进度...',
            time:' 2021年03月10日'
            },
          {
            url:require("../assets/img/14.png"),
            title:'华为“5G超级上行”解决方案，荣获GTI 2021“移动技术创新突破”奖',
            time:' 2021年03月10日'
            },
          {
            url:require("../assets/img/15.png"),
            title:'城市最小管理单元数字治理成果发布，迈出上海城市智能体落地关键一步',
            time:' 2021年03月10日'
            }
    ] 
      }
    },
    methods: {
      onSubmit() {
      },
      handleSizeChange(val) {
      },
      handleCurrentChange(val) {
      }
    }
  }
</script>
<style scoped>
@charset "utf-8";
#app{background-color: #fff;}
.main{margin: 88px auto 0; width: 100%; }
.content-title{max-width: 1920px; position: relative; height: 360px; overflow: hidden;}
.content-title img{display: block; width: 100%; height: 100%; object-fit: cover;}
.hidden-xs{display: block; width: 100%; height: 100%; object-fit: cover;}
.hidden-mx{display: none;}
.content-box{position: absolute; left:0; right:0; top:0; bottom:0; z-index: 99; display: -webkit-flex; display: flex; flex-flow: column wrap; justify-content: center; margin: 0 auto; max-width: 530px; }
.content-box h1,.content-box div{text-align: center;}
.white .content-box h1,.white .content-box div{color: #fff;}
.black .content-box h1,.black .content-box div{color: #363636;}
.content-box h1{font-size: 40px; line-height: 52px}
.content-box div{margin: 13px 0 0; font-size:16px; line-height: 22px;}
.panel{position: relative; z-index: 999; margin:60px auto 0; padding:0;  width: 100%;}

.listBox,.listBox-link,.listBox-item-text{display: -webkit-flex; display: flex;  margin: 0 auto; }
.listBox-item-title:hover{color: #0B46B1;}
/* 要闻 */
#imageText .listBox{flex-flow:row wrap; justify-content: space-between; max-width: 1400px;}
#imageText .listBox-item{position: relative; margin: 0 2.28% 32px 0; width: calc((100% - 2.28% * 2) / 3); background-color: #fff; box-shadow:0 10px 20px rgba(175, 174, 187, .16); }
#imageText .listBox-item:nth-child(3n){margin: 0 0 32px 0; }
#imageText .listBox-link{flex-flow: column wrap; justify-content: space-between;}
#imageText .listBox-link .listBox-item-text{flex-flow: column wrap; justify-content: space-between;}
#imageText .listBox-link .listBox-item-img{display: block; width: 100%; height:300px;}
#imageText .listBox-link .listBox-item-img img{margin: 0 auto; display: block; width: 100%; height: 100%; object-fit: cover;}
#imageText .listBox-item-text .listBox-item-title{padding:18px 18px 0; width: 100%; height: calc(28px * 2 + 18px); font-size: 20px; font-weight: 700; line-height: 28px; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; overflow: hidden;
    /*！ autoprefixer: off */
      -webkit-box-orient: vertical;
    /* autoprefixer: on */ }
#imageText .listBox-item-text .listBox-item-tip{display: none; visibility: hidden;}
#imageText .listBox-item-text .listBox-item-time{padding: 18px 18px 26px; width: 100%; font-size: 12px; line-height: 16px; color: rgb(29 29 31 / 50%);}

/* 通知 */
#text .listBox{flex-flow:row wrap; justify-content: space-between; max-width: 1120px;}
#text .listBox-item{position: relative; margin: 0 auto; width: 100%; border-bottom: 1px solid #eee; }
#text .listBox-link{flex-flow: row nowrap; justify-content: space-between;}
#text .listBox-link .listBox-item-img,#text .listBox-link .listBox-item-tip{display: none; visibility: hidden;}
#text .listBox-link .listBox-item-text{flex-flow: row nowrap; justify-content: space-between;width: 100%;}
#text .listBox-item-text .listBox-item-title{margin:26px 0; width: 80%; min-height: 64px; font-size: 24px; font-weight: 700; line-height: 32px; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; overflow: hidden;
    /*！ autoprefixer: off */
      -webkit-box-orient: vertical;
    /* autoprefixer: on */ }
#text .listBox-item-text .listBox-item-time{display: -webkit-flex; display: flex; flex-flow: column wrap; justify-content: center; width: 20%; font-size: 16px; line-height: 24px; color: rgba(29, 29, 31, .5); text-align: right;}

@media (max-width: 1500px){
    .listBox,#imageText .listBox{max-width: auto; width: 90%;}
}
@media (max-width: 1024px){
    #imageText .listBox-link .listBox-item-img{height:260px;}
}

@media (max-width: 990px){
    .main{margin: 64px auto 0; }
    .hidden-xs{display:none;}
    .hidden-mx{display: block; width: 100%; height: 100%; object-fit: cover;}
    .panel{padding:0 0 40px;}
    #imageText .listBox-item{width: calc((100% - 2.28% * 2) / 2);}
    #imageText .listBox-item:nth-child(2n){margin: 0 0 32px 0; }
    #imageText .listBox-link .listBox-item-img{height:300px;}
}

@media (max-width: 768px){
    #imageText .listBox-link .listBox-item-img{height:240px;}
    #text .listBox-link .listBox-item-text{flex-flow: row nowrap; justify-content: flex-start; margin: 26px 0;}
    #text .listBox-item-text .listBox-item-title{margin: 0; padding: 0; width: 76%; min-height: 64px; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; overflow: hidden;
        /*！ autoprefixer: off */
          -webkit-box-orient: vertical;
        /* autoprefixer: on */ }
    #text .listBox-item-text .listBox-item-time{width: 24%;}
}
@media (max-width: 640px){
    #app{background: #F4F6F8;}
    .main{margin: 1.28rem auto 0; }
    .content-title{padding:.4rem .48rem .36rem; width: 100%; height: auto;}
    .content-title img,.content-box div{display: none;}
    .content-box{position: relative; padding: 0; width: 100%; height: auto;}
    .content-box h1,.content-box div{text-align: left; }
    .white .content-box h1,.white .content-box div,.black .content-box h1,.black .content-box div{color: #212121; }
    .content-box h1{font-size: .64rem; line-height: .96rem}
    .content-box div{margin: .1rem 0 0; font-size:.28rem; line-height: .4rem;}
    .panel{margin: 0 auto; padding: 0;}
    .listBox,#imageText .listBox{padding: 0 .48rem; width: 100%;}

    #imageText .listBox .listBox-item, #imageText .listBox .listBox-item:nth-child(3n), #yaowei .listBox .listBox-item:nth-child(2n){margin: 0 0 .48rem; width: 100%; box-shadow:0 .2rem .4rem rgba(175, 174, 187, .16); }
    #imageText .listBox-link .listBox-item-img{height: 3.6rem;}
    #imageText .listBox-item-text .listBox-item-title{padding: .34rem .26rem 0; height: calc(.5rem * 2 + .34rem); font-size: .36rem; line-height: .5rem;}
    #imageText .listBox-item-text .listBox-item-time{padding: .26rem .26rem .46rem; width: 100%; font-size: .26rem; line-height: .36rem; color: rgb(29 29 31 / 50%);}
    .listBox-item-title:hover{color: #212121;}

    #text .listBox-link{flex-flow: column nowrap; justify-content: flex-start; margin: .52rem 0;}
    #text .listBox-item:nth-child(1) .listBox-link{margin: 0 0 .52rem;}
    #text .listBox-link .listBox-item-text{flex-flow: column nowrap; justify-content: flex-start; margin: 0;}
    #text .listBox-item-text .listBox-item-title{margin: 0 auto; padding:0; width: 100%; min-height: calc(.56rem * 2); font-size:.36rem; line-height: .56rem; }
    #text .listBox-item-text .listBox-item-time{display: inline-block; margin: .1rem 0 0; width: 100%; font-size: .26rem; line-height: .36rem; text-align: left;}
    .paging >>> .el-pagination .btn-next,.paging >>> .el-pagination .btn-prev,.paging >>> .el-pagination button, .el-pagination span:not([class*=suffix]){background: transparent;}
}
</style>
